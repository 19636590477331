

//import { useAuth0 } from '@auth0/auth0-react';
import ReactGA from 'react-ga';


import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SearchPage from './components/SearchPage';

import CallbackPage from './components/Callback';
const GOOGLE_ANALYTICS_TAG = process.env.READCT_APP_UA_ID;
ReactGA.initialize(GOOGLE_ANALYTICS_TAG);
function App() {
  //const { isAuthenticated } = useAuth0();
  return (
    <Router>
    <Routes>
          <Route index path="/" element={<SearchPage />} /> 
          
          <Route  path="/search" element={<SearchPage />} /> 

    </Routes>
    </Router>
  );
}

export default App;
