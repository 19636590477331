import React from 'react';
import ImageActionButtons from './Gallery/ImageActionButtons';
export default function ImageGallery({ images, onImageClick, showActionButtons, onInclude, onExclude, exclude, include }) {
  return (
    <div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-12 mt-4 place-items-center ">       
        {images.map((image, index) => (
          <div key={index}>
          <div className={`flex flex-col items-center max-w-sm rounded overflow-hidden shadow-lg ${exclude.includes(image.id) ? 'opacity-50' : ''} ${include.includes(image.id) ? 'ring-4 ring-blue-500' : ''}`}>
            <div className="relative">
    
            <img alt="MyRidePhotos" src={image.image} className={`object-contain w-full h-full ${include.includes(image.id) ? 'opacity-100' : ''}`} onClick={() => onImageClick && onImageClick(image)} />
            </div>
            
          </div>
          {showActionButtons && ImageActionButtons({ imageId: image.id, onInclude, onExclude, disabled: exclude.includes(image.id) || include.includes(image.id) })}
          </div>
        ))}
      </div>
    </div>
  );
}
